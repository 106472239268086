<template>
    <div
        v-if="dataSource && dataSourceType == 'Meeting'"
        :key="meeting.id"
        class="p-relative"
    >
        
        <Toolbar 
            :menu="menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel
            :document-info="dataSource.Data.Object"
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="meeting.id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="meeting.id + item.FormId"
            >

                <!--#region Карточка -->
                <template v-if="item.FormId == meeting.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>

                            <!--#region Основная информация -->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                
                                <v-card-panel title="Основная_информация">

                                    <!-- Тема -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Тема")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ meeting.Subject }}
                                            </div>
                                    
                                            <template v-else>
                                                <v-textarea
                                                    :value="meeting.Subject"
                                                    @input="update({ property: 'Data.Object.Subject', value: $event})"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </template>
                                        </v-col>
                                     </v-row>

                                    <!-- Номер (только у существующих) -->
                                    <v-row no-gutters v-if="!meeting.is_new_record">
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Номер")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ meeting.Number }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    
                                    <!-- Дата мероприятия -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дата_мероприятия")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ formatDate(meetingDate) }}
                                            </div>
                                            <template v-else>
                                                <v-menu 
                                                    v-model="meetingDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="meetingDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="meetingDate"
                                                        @input="meetingDateMenu = false"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>

                                            </template>
                                        </v-col>
                                    </v-row>

                                    <!-- Время начала -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Время_начала")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ beginTime }}
                                            </div>
                                            <template v-else>
                                                <vue-timepicker 
                                                    v-model="beginTime"
                                                    placeholder=' '
                                                    close-on-complete
                                                    hide-clear-button
                                                    :class="!timeIsValid() ? 'time-empty':''"
                                                    hide-disabled-items
                                                    :hour-range="[[8, 20]]"
                                                    :minute-interval="10"
                                                >
                                                    <template v-slot:clearButton>
                                                        <i class="fas fa-times"></i>
                                                    </template>
                                                </vue-timepicker>
                                            </template>
                                        </v-col>
                                    </v-row>

                                    <!-- Планируемое время окончания -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Планируемое_время_окончания")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ endTime }}
                                            </div>
                                            <template v-else>
                                                <vue-timepicker 
                                                    v-model="endTime"
                                                    placeholder=' '
                                                    close-on-complete
                                                    hide-clear-button
                                                    hide-disabled-items
                                                    :hour-range="[[8, 20]]"
                                                    :minute-interval="10"
                                                >
                                                    <template v-slot:clearButton>
                                                        <i class="fas fa-times"></i>
                                                    </template>
                                                </vue-timepicker>    
                                            </template>
                                        </v-col>
                                    </v-row>

                                    <!-- Планируемая продолжительность -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Планируемая_продолжительность")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ meetingDuration }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!-- Фактическое время окончания -->
                                    <v-row no-gutters v-if="!meeting.is_new_record && realEndTime">
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Фактическое_время_окончания")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ realEndTime }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!-- Место проведения -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Место_проведения")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ meeting.Place }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="meeting.Place"
                                                @input="update({ property: 'Data.Object.Place', value: $event })"
                                                hide-details
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!-- Статус -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Статус") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                               {{ meeting.Status }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!-- Автор -->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Автор")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                <v-employee-chip :id="meeting.AuthorEmployeeId" :name="meeting.AuthorName" />
                                            </div>
                                        </v-col>
                                    </v-row>
                                    
                                </v-card-panel>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                <v-card-panel title="Участники_мероприятия">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Инициатор") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group icrg-align-center">
                                                <div v-if="!isViewMode" @click="onSelectPerson('Initiator')" :key="meeting.InitiatorWorkplaceId">
                                                    <v-icon small v-tooltip.left-center="selectInitiatorTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div class="onlyReadData">
                                                    <v-workplace-chip v-if="meeting.InitiatorName" :id="meeting.InitiatorWorkplaceId" :name="meeting.InitiatorName" />
                                                    <div class="chipAreaNoData" v-if="!meeting.InitiatorName"></div>
                                                </div>                             
                                            </div>
                                        </v-col>
                                    </v-row>  

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Секретарь") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group icrg-align-center">
                                                <div v-if="!isViewMode" @click="onSelectPerson('Secretary')" :key="meeting.SecretaryWorkplaceId">
                                                    <v-icon small v-tooltip.left-center="selectSecretaryTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div class="onlyReadData">
                                                    <v-workplace-chip v-if="meeting.SecretaryName" :id="meeting.SecretaryWorkplaceId" :name="meeting.SecretaryName" />
                                                    <div class="chipAreaNoData" v-if="!meeting.SecretaryName"></div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Контролер") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group icrg-align-center">
                                                <div v-if="!isViewMode" @click="onSelectPerson('Controller')" :key="meeting.ControllerWorkplaceId">
                                                    <v-icon small v-tooltip.left-center="selectControllerTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div class="onlyReadData">
                                                    <v-workplace-chip v-if="meeting.ControllerName" :id="meeting.ControllerWorkplaceId" :name="meeting.ControllerName" />
                                                    <div v-else class="onlyReadData">
                                                        {{ $t("Не_указано") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Участники")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group icrg-align-center">
                                                <div v-if="!isViewMode" @click="onSelectMembers">
                                                    <v-icon small v-tooltip.left-center="selectMembersTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div class="onlyReadData" v-if="!meeting.Members || meeting.Members.length == 0">
                                                    <div class="chipAreaNoData"></div>
                                                </div>
                                                <div v-else class="onlyReadData more-per-lab-wrap">
                                                    <template v-for="member in meeting.Members">
                                                        <v-workplace-chip :key="member.WorkplaceId" :id="member.WorkplaceId" :name="member.Name" />
                                                    </template>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row> 
                                </v-card-panel>
                            </v-col>

                        </v-row>
                    </v-form>
                </template>

                <!--#region Повестка -->
                <template v-if="item.FormId == 'MeetingAgenda'">
                    <v-form class="wrapperForm" ref="form" lazy-validation>

                        <div
                            v-if="!isViewMode"
                            class="create-oth-incd-before-card-first more-per-lab-wrap"
                        >
                            <v-chip
                                class="person-label immutable-text"
                                small
                                label
                                color="orange lighten-3"
                                @click="onAddMeetingQuestion"
                                v-tooltip.left-center="addMeetingQuestionTooltip"
                            >
                                <v-icon color="#494F59">fas fa-plus</v-icon>
                                <div>{{$t("Добавить_вопрос")}}</div>
                            </v-chip>                        
                        </div>

                        <v-row dense>
                            <v-col cols="12" sm="12" md="12" class="section-wrapper" v-if="meeting.Questions">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Перечень_вопросов") }}</div>

                                        <v-data-table
                                            :headers="currentHeaders"
                                            :items="questionsSorted"
                                            class="elevation-1 customization-list-table sticky-table"
                                            :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                                            hide-default-footer
                                        >
                                            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>
                                            
                                            <template v-slot:[`item.Move`]="{ item }">
                                                <!--<v-icon
                                                    v-if="item.Number != 1"
                                                    small
                                                    @click="onMoveQuestion(item, 'up')"
                                                >
                                                    mdi-arrow-up
                                                </v-icon>
                                                <v-icon
                                                    v-if="item.Number != meeting.Questions.length"
                                                    small
                                                    @click="onMoveQuestion(item, 'down')"
                                                >
                                                    mdi-arrow-down
                                                </v-icon>-->
                                                <span
                                                    v-if="item.Number != 1"
                                                    @click="onMoveQuestion(item, 'up')"
                                                    class="tbl-action-button"
                                                >
                                                    <i class="fas fa-arrow-up"></i>
                                                </span>
                                                <span
                                                    v-if="item.Number != meeting.Questions.length"
                                                    @click="onMoveQuestion(item, 'down')"
                                                    class="tbl-action-button"
                                                >
                                                    <i class="fas fa-arrow-down"></i>
                                                </span>
                                            </template>

                                            <template v-slot:[`item.Subject`]="{ item }">
                                                <div class="etw-text-container">
                                                    <div class="etw-text" v-tooltip.top-center="item.Subject">
                                                        {{ item.Subject }}
                                                    </div>
                                                </div>
                                            </template>
                                            
                                            <template v-slot:[`item.Duration`]="{ item }">
                                                {{ item.Duration }}
                                            </template>

                                            <template v-slot:[`item.SpeakerName`]="{ item }">
                                                <v-workplace-chip :id="item.SpeakerWorkplaceId" :name="item.SpeakerName" />
                                            </template>

                                            <template v-slot:[`item.Actions`]="{ item }">
                                                <!--<v-icon
                                                    small
                                                    class="mr-2"
                                                    @click="onEditQuestion(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                                <v-icon
                                                    small
                                                    @click="onDeleteQuestion(item)"
                                                >
                                                    mdi-delete
                                                </v-icon> 
                                                -->
                                                <span
                                                    @click="onEditQuestion(item)"
                                                    class="tbl-action-button"
                                                    v-tooltip.left-center="$t('Редактировать')"
                                                >
                                                    <i class="fas fa-edit"></i>
                                                </span>
                                                <span
                                                    @click="onDeleteQuestion(item)"
                                                    class="tbl-action-button"
                                                    v-tooltip.left-center="$t('Удалить')"
                                                >
                                                    <i class="fas fa-trash"></i>
                                                </span>
                                                                                               
                                            </template>

                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-form>  
                </template>

                <template v-if="item.FormId == 'MeetingAttachments'">
                    <attachments-tab
                        :ref="item.FormId"
                        :id="meeting.id"
                    />
                </template>
                
            </v-tab-item>

        </v-tabs-items>

    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';
import _ from 'lodash';
import i18n from '@/i18n';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
    name: "MeetingCard",
    components: {
        Toolbar,
        DocumentPanel,
        VueTimepicker
    },
    data() {
        return {
            tab: null,
            meetingDateMenu: false,
            requiredRules: [
                v => !!v || v === 0 || 'Поле_обязательно',
            ]
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('references', ['GetReference']),
        ...mapGetters('actionsource', { dataSource: 'getDataSource', dataSourceType: 'getDataSourceType' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            integerNotNull: 'getIntegerNotNullRule',
            integerRule: 'getIntegerRule'
        }),
        meeting() {
            return this.dataSource?.Data?.Object;
        },
        isViewMode() {
            return this.dataSource?.Data?.FormId == "1701021";
        },
        menu() {
            if (this.isViewMode) {
                return [...this.dataSource.Data.Menu, { Action: "AddFile", Icon: "attach_add", Items: null, Text: "Вложить файл", ToolTip: "Вложить файл в документ", Type: null }];
            }
            else return this.dataSource.Data.Menu;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.meeting.id, Name: this.$t("Карточка") }
            ];

            if (!this.meeting.is_new_record) {
                pages.push({ Count: this.meeting.Questions.length, FormId: `MeetingAgenda`, Name: this.$t("Повестка") });
                pages.push({ Count: this.meeting.AttachmentsSource?.length ?? 0, FormId: `MeetingAttachments`, Name: this.$t("Материалы_мероприятия") });
            }

            return pages;
        },
        questionsSorted() {
            return this.$_(this.meeting.Questions).orderBy(['Number'], ['asc']).value();
        },
        currentHeaders() {
            let headers = [
                { text: i18n.t("Номер_по_порядку"), value: 'Number', width: '150px', sortable: false },
                { text: i18n.t("Текст_вопроса"), value: 'Subject', sortable: false },
                { text: i18n.t("Выступающий"), value: 'SpeakerName', width: '20%', sortable: false },
                { text: i18n.t("Длительность,_мин"), value: 'Duration', align: 'center', width: '150px', sortable: false },
            ];

            if (!this.isViewMode) {
                headers.unshift({ text: '', value: 'Move', sortable: false, width: '49px' });
                headers.push({ text: '', value: 'Actions', sortable: false, width: '52px' });
            }

            return headers;
        },
        selectInitiatorTooltip() {
            return this.$t("Выбрать_инициатора");
        },
        selectSecretaryTooltip() {
            return this.$t("Выбрать_секретаря");
        },
        selectControllerTooltip() {
            return this.$t("Выбрать_контролера");
        },
        selectMembersTooltip() {
            return this.$t("Выбрать_участников");
        },
        addMeetingQuestionTooltip() {
            return this.$t("Добавить_вопрос");
        },
        //#region Даты, интервалы
        meetingDate: {
            get: function() {
                if (this.meeting.Date)
                    return this.$moment(this.meeting.Date).format('YYYY-MM-DD');
                
                return this.meeting.Date;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Date', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        beginTime: {
            get: function() {
                return this.meeting.BeginTime ? this.$moment.utc(this.$moment.duration(this.meeting.BeginTime).as('milliseconds')).format('HH:mm') : "00:00";
            },
            set: function(v) {
                this.updateSource({ property: 'Data.Object.BeginTime', value: this.$moment.duration(v + ':00').toJSON() });
            }
        },
        endTime: {
            get: function() {
                return this.meeting.EndTime ? this.$moment.utc(this.$moment.duration(this.meeting.EndTime).as('milliseconds')).format('HH:mm') : "00:00";
            },
            set: function(v) {
                this.updateSource({ property: 'Data.Object.EndTime', value: this.$moment.duration(v + ':00').toJSON() });
            }
        },
        realEndTime() {
            return this.meeting.RealEndTime ? this.$moment.utc(this.$moment.duration(this.meeting.EndTime).as('milliseconds')).format('HH:mm') : null;
        },
        meetingDuration() {
            let beginDateTime = this.$moment(this.beginTime, 'HH:mm');
            let endDateTime = this.$moment(this.endTime, 'HH:mm');

            let minutesDiff = endDateTime.diff(beginDateTime, 'minutes');

            let hourDuration = Math.floor(minutesDiff / 60);
            let minutesTail = minutesDiff % 60;

            let hoursLabel = "часов";

            if (hourDuration == 1)
                hoursLabel = "час";

            if (hourDuration > 1 && hourDuration < 5)
                hoursLabel = "часа";

            let minutesLabel = "минут";

            if (minutesTail == 1)
                minutesLabel = "минута";

            if (minutesTail > 1 && minutesTail < 5)
                minutesLabel = "минуты";
            
            if(minutesDiff > 0)
                return `${hourDuration} ${this.$t(hoursLabel)} ${minutesTail} ${this.$t(minutesLabel)}`;
            
            return '-';
        }
        //#endregion
    },
    methods: {
        ...mapActions('references', ['getReference']),
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),   
        onTabClicked (formId) {
            if (this.$refs[formId])
                this.$refs[formId][0]?.update();
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => { return i.split('/')[1] });

            if (actions.includes(button.Action)) {

                if (button.Action == 'Save' || button.Action == 'SaveAndClose') {
                    let validate_result = this.$refs.form[0].validate();
                    
                    if (validate_result && this.timeIsValid())
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                    else
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                }
                else
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
            }
            else
                this.$notify.alert(`Action ${button.Action} not emplemented`);
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },
        timeIsValid() {
            let beginDateTime = this.$moment(this.beginTime, 'HH:mm');
            let endDateTime = this.$moment(this.endTime, 'HH:mm');
            let minutesDiff = endDateTime.diff(beginDateTime, 'minutes');
            return minutesDiff > 0;
        },
        async onSelectPerson(role) {
            if (!role)
                return;
            
            try
            {
                let title = "";
                let selectedId = "";

                switch (role) {
                    case 'Initiator':
                        title = "Выбор_инициатора";
                        selectedId = this.meeting.InitiatorWorkplaceId;
                        break;

                    case 'Secretary':
                        title = "Выбор_секретаря";
                        selectedId = this.meeting.SecretaryWorkplaceId;
                        break;

                    case 'Controller':
                        title = "Выбор_контролера";
                        selectedId = this.meeting.ControllerWorkplaceId;
                        break;

                    default: 
                        break;
                }

                let selectMemberParams =  { 
                    title: i18n.t(title),
                    includeInner: true,
                    includeOuter: true,
                    multiple: false,
                    selected: [ selectedId ]
                }

                let { workplaceId, employeeId, name } = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);

                this.updateSource({ property: `Data.Object.${role}WorkplaceId`, value: workplaceId });
                this.updateSource({ property: `Data.Object.${role}EmployeeId`, value: employeeId });
                this.updateSource({ property: `Data.Object.${role}Name`, value: name });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async onSelectMembers() {
            try
            {
                let selected = this.meeting.Members?.map( member => member.WorkplaceId) ?? [];

                let selectMemberParams =  { 
                    title: i18n.t("Выбор_участников"),
                    includeInner: true,
                    includeOuter: true,
                    multiple: true,
                    selected
                }

                let result = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.updateSource({ property: `Data.Object.Members`, value: result.map(x => ({ MeetingId: this.meeting.id, Name: x.name, EmployeeId: x.employeeId, WorkplaceId: x.workplaceId, Status: 0 })) });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async onAddMeetingQuestion() {
            let result = await this.$store.dispatch('dialogs/addMeetingQuestion/open', { members: this.meeting.Members });
            let questions = Array.from(JSON.parse(JSON.stringify(this.meeting.Questions)));

            questions.push({
                MeetingId: this.meeting.id,
                Number: questions.length + 1,
                Subject: result.subject,
                Duration: result.duration,
                SpeakerEmployeeId: result.speaker.SpeakerEmployeeId,
                SpeakerWorkplaceId: result.speaker.SpeakerWorkplaceId,
                SpeakerName: result.speaker.SpeakerName,
            });

            this.updateSource({ property: `Data.Object.Questions`, value: questions });
        },
        async onEditQuestion(item) {

            let indexOfQuestion = this.meeting.Questions.indexOf(item);

            if (indexOfQuestion < 0)
                return;

            let question = { 
                subject: item.Subject,
                duration: item.Duration,
                speaker: { 
                    SpeakerEmployeeId: item.SpeakerEmployeeId,
                    SpeakerWorkplaceId: item.SpeakerWorkplaceId,
                    SpeakerName: item.SpeakerName
                }
            };

            let result = await this.$store.dispatch('dialogs/addMeetingQuestion/open', { members: this.meeting.Members, question });
            let questions = Array.from(JSON.parse(JSON.stringify(this.meeting.Questions)));

            questions[indexOfQuestion].Subject = result.subject;
            questions[indexOfQuestion].Duration = result.duration;
            questions[indexOfQuestion].SpeakerEmployeeId = result.speaker.SpeakerEmployeeId;
            questions[indexOfQuestion].SpeakerWorkplaceId = result.speaker.SpeakerWorkplaceId;
            questions[indexOfQuestion].SpeakerName = result.speaker.SpeakerName;

            this.updateSource({ property: `Data.Object.Questions`, value: questions });
        },
        onDeleteQuestion(item) {
            let indexOfQuestion = this.meeting.Questions.indexOf(item);

            if (indexOfQuestion < 0)
                return;

            let questions = Array.from(JSON.parse(JSON.stringify(this.meeting.Questions)));
            questions.splice(indexOfQuestion, 1);

            for (let i = 0; i < questions.length; i++) {
                questions[i].Number = i + 1;
            }

            this.updateSource({ property: `Data.Object.Questions`, value: questions });
        },
        onMoveQuestion(item, direction) {
            let idx = item.Number - 1;
            let targetIndex = direction == 'up' ? idx - 1 : idx + 1;
            let questions = Array.from(JSON.parse(JSON.stringify(this.meeting.Questions)));
            let tmp = questions[targetIndex];
            questions[targetIndex] = questions[idx];
            questions[idx] = tmp;

            for (let i = 0; i < questions.length; i++) {
                questions[i].Number = i + 1;
            }

            this.updateSource({ property: `Data.Object.Questions`, value: questions });
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250)
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    }
}
</script>